.mt-40 {
  top: 40%;
}



.privacy-policy {
  position: relative;
  margin-top: 80px;
  padding: 50px 0;
  overflow: hidden;
  background-color: #ffffff;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 5%;
}

.privacy-policy h1 {
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-bottom: 40px;
}

.privacy-policy h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  margin-top: 40px;
  margin-bottom: 20px;
}

.privacy-policy h3 {
  font-size: 22px;
  font-weight: bold;
  color: #555555;
  margin-top: 30px;
  margin-bottom: 15px;
}

.privacy-policy p,
.privacy-policy li {
  font-size: 16px;
  line-height: 1.6;
  color: #666666;
  margin-bottom: 20px;
}

.privacy-policy ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.privacy-policy a {
  color: #006dff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy .title {
  line-height: 1.2;
  color: #333333;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 20px;
}

.privacy-policy .jump-title {
  margin-top: 12px;
  color: #777777;
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}


strong {
  font-weight: bold !important;
}

.thankspart {
  margin-top: 76px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.thankspart-content {
  padding: 2em;
  margin: 0 auto;
  margin-bottom: 2em;
  overflow: hidden;
}

.thankspartner {
  margin-top: 25px;
}

.thankspartner img {
  height: 250px;
  display: inline-block;
  padding-right: 15px;
}

.thankspartner p {
  width: 730px;
  font-size: 16px;
  text-align: left;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .thankspart-content {
    margin-left: 0;
    border-radius: 0;
    padding: 1em;
  }  
}
