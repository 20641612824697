.home-page {
    font-family: 'PT Sans', sans-serif !important;
    background-color: #1b1f25;
}

.img-hover-container {
    position: absolute;
    top: 150px;
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.img-hover-container .title {
    font-weight: bold;
    font-size: 60px;
    color: #fff;
    line-height: 60px;
}

.img-hover-container .desc {
    margin-top: 20px;
    font-size: 24px;
    color: #fff;
    line-height: 20px;
}

.img-hover-container button {
    font-family: 'PT Sans', sans-serif;
    margin-top: 40px;
    border-radius: 24px;
    background-color: #006dff;
    border: none;
    height: 40px;
    width: 168px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
}

.img-hover-container .images-decorate {
    position: absolute;
    top: 220px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;


}

.img-hover-container .images-main {
    position: absolute;
    top: 220px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;


}

.img-hover-container .images-decorate img {
    height: 432px;
}

.img-hover-container .images-main img {
    height: 442px;
}

.dashboard-image {
    margin-top: 72px;
}

.dashboard-image img {
    width: 100%;
    height: 780px;
    object-fit: fill;
}

.dashboard-categories {
    padding: 72px 72px;
    text-align: center;
}

.dashboard-categories .categories .category {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.dashboard-categories .title {
    font-weight: bold;
    color: #fff;
    font-size: 36px;
    line-height: 36px;
}

.dashboard-categories .categories {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

.dashboard-categories .categories .category img {
    width: 142px;
    border-radius: 4%;
}

.dashboard-categories .categories .category .category-name {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
}

.section {
    width: 90%;
    margin: auto;
    padding: 80px 0px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.section .section-image {
    width: 50%;
    border-radius: 8px;
}

.section .section-description {
    width: 40%;
}

.section .section-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.section .section-description .title {
    color: #fff;
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
}

.section .section-description .desc {
    margin-top: 20px;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
}

.section .section-description button {
    font-family: 'PT Sans', sans-serif;
    margin-top: 20px;
    border-radius: 24px;
    background-color: #006dff;
    border: none;
    height: 40px;
    width: 168px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}

@media (max-width: 1024px) {
    .img-hover-container .images-decorate {
        top: 284px;
    }

    .img-hover-container .images-decorate img {
        height: 300px;
    }
}

.plan-section {
    text-align: center;
    padding: 80px 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan-section .title {
    text-align: center;
    color: #fff;
    font-size: 52px;
    line-height: 60px;
    font-weight: bold;
}

.plan-section .plan-box {
    padding: 32px;
    border: solid 1px #676767;
    border-radius: 20px;
    max-width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 216px;
}

.plan-section .plan-box .title {
    display: flex;
    color: #fff;
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
}
.plan-section .plan-box .contents {
    text-align: left;
    margin-top: 20px;
    font-size: 18px;
    color: #FFF;
    line-height: 20px;
}

.plan-section .plan-box button {
   font-family: 'PT Sans', sans-serif;
    margin-top: 40px;
    border-radius: 24px;
    background-color: #006dff;
    border: none;
    height: 40px;
    width: 100%;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}

@media (max-width: 768px) {
    .plans {
        flex-direction: column;
        align-items: center;
    }

    .plan-section {
        padding: 40px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mt-5 {
        margin-top: 0px !important;
    }

    .plan-section .title {
        font-size: 40px;
        line-height: 40px;
    }

    .plan-section .plan-box {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 40px;
        max-width: 80% !important;
    }

    .img-hover-container {
        top: 104px;
    }

    .img-hover-container .desc {
        padding: 0px 32px;
    }

    .img-hover-container .apple-store, .img-hover-container .google-play {
        width: 172px;
        margin-top: 20px;
        border-radius: 8px;
        display: inline;
        margin: 16px 5px;
    }

    .img-hover-container .title {
        font-size: 40px;
        line-height: 40px;
    }

    .img-hover-container button {
        margin-top: 20px;
    }

    .img-hover-container .main-image img {
        height: calc(100vh - 482px);
    }

    .dashboard-categories {
        padding: 40px 20px;

    }

    .dashboard-categories .category img {
        width: 100% !important;
    }

    .dashboard-categories .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    }

    .reverse {
        flex-direction: column-reverse !important;
    }

    .section {
        flex-direction: column;
        padding: 20px;

    }

    .section .section-image,
    .section .section-description {
        align-items: center;
        width: 100%;
        margin-top: 20px;

    }

    .section .section-description .title {
        font-size: 24px;
        line-height: 40px;
        text-align: center;
    }

    .section .section-description .desc {
        text-align: center;
        font-size: 16px;
    }

    .section .section-description button {
        margin: 20px auto;
    }
}

.desktop-hidden {
  display: none;
}

.desktop-hidden .google-play {
    border-radius: 8px;
}

.mobile-hidden {
  display: block;
}

@media screen and (max-width: 768px) {
  .mobile-hidden {
    display: none;
  }

  .desktop-hidden {
    display: block;
    margin-top: 16px;
  }
}