@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.admin-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  transition: all 0.35s ease;
}

.item-a {
    grid-area: header;
}
.item-b {
    width: 96%;
}
.item-c {
    grid-area: sidebar;
}
.item-d {
    grid-area: footer;
}

.admin-container {
  height: auto !important;
  margin: 0 auto !important;
  padding: 0 1.25rem !important;
}

.admin-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.25rem;
  width: 100%;
  height: 4.25rem !important;
  margin: 0 auto;
  background-color: #1b1f25;
}

.brand {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -1px;
  color: #FFF;
  text-transform: uppercase;
}

.menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  padding: 4rem 0 3rem;
  overflow: hidden;
  background-color: #1b1f25;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.4s ease-in-out;
}

.menu.is-active {
  top: 0;
  width: 100%;
  height: auto;
}

.menu-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.25rem;
}

.menu-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #FFF;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.menu-block {
  margin-right: 2rem;
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  margin-left: auto;
  padding: 0.65rem 1.5rem;
  border-radius: 3rem;
  text-transform: capitalize;
  color: #FFF;
  background-color: #006dff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 48rem) {
  .menu {
    position: relative;
    top: 0;
    width: auto;
    height: auto;
    padding: 0rem;
    margin-left: auto;
    background: none;
    box-shadow: none;
  }

  .menu-inner {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin: 0 auto;
  }

  .menu-link {
    text-transform: capitalize;
  }

  .menu-block {
    margin-left: 2rem;
  }
}

.burger {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  order: -1;
  z-index: 10;
  width: 1.6rem;
  height: 1.15rem;
  border: none;
  outline: none;
  background: none;
  visibility: visible;
  transform: rotate(0deg);
  transition: 0.35s ease;
}
.burger-line {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    border: none;
    outline: none;
    opacity: 1;
    border-radius: 1rem;
    transform: rotate(0deg);
    background-color: #FFF;
    transition: 0.25s ease-in-out;
}

.burger-line:nth-child(1) {
  top: 0px;
}

.burger-line:nth-child(2) {
  top: 0.5rem;
  width: 70%;
}

.burger-line:nth-child(3) {
  top: 1rem;
}

.burger.is-active .burger-line:nth-child(1) {
  top: 0.5rem;
  transform: rotate(135deg);
}

.burger.is-active .burger-line:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.burger.is-active .burger-line:nth-child(3) {
  top: 0.5rem;
  transform: rotate(-135deg);
}

@media only screen and (min-width: 48rem) {
  .burger {
    display: none;
    visibility: hidden;
  }
}

.admin-section {
  margin-top: 120px;
  min-height: 400px;
  padding: 0rem 0 1rem !important; 
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

button {
  border: 0px;
  border-radius: 4px;
  color: #FFF;
  cursor: pointer;
}

.assign-btn {
  background: #006dff;
}

.assign-btn:hover {
  background: #2f7fe7;
}

.delete-btn {
  background-color: #e32c2c;
}

.password-link-btn {
  background-color: #099d6e;
}

.msg {
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
}

.user-results {
  margin-top: 32px;
  line-height: 38px;
  font-size: 16px;
  color: #FFF;
}

.wrap .search {
  width: 100%;
  position: relative;
  display: flex;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px !important;
}

.wrap .search .searchTerm {
  font-size: 20px;
  width: 100%;
  border: 2px solid #1b1f25;
  border-right: none;
  padding: 5px 5px 5px 10px;
  height: 52px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
  margin-left: 0px !important;
}

.wrap .search .searchTerm:focus{
  color: #1b1f25;
}

.wrap .search .searchButton {
  width: 40px;
  height: 52px;
  border: 1px solid #1b1f25;
  background: #1b1f25;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 82%;
}

.home-title {
  font-size: 20px;
  color: #fff;
}