.st-footer {
    max-width: 2560px;
    margin: 0px auto;
    padding: 0px 48px 50px;
    background-color: #fff;
}

.st-footer .title {
    padding-top: 30px;
    line-height: 30px;
    text-align: center;
}

.st-footer .title a {
    font-size: 24px;
    color: #344050;
}

.st-footer footer {
    display: flex;
    gap: 50px;
    width: 100%;
    padding: 50px 12px 30px;
}

footer .left-column {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 294px;
}

footer .left-column p {
    font-weight: 500;
    color: #21242a;
    padding: 0px;
    margin: 0px;
    text-decoration: unset;
    cursor: unset;
    font-size: 14px;
    line-height: 22px;
}

footer .left-column .social-icons {
    display: flex;
    -webkit-box-pack: unset;
    justify-content: unset;
    -webkit-box-align: unset;
    align-items: unset;
    width: unset;
    min-width: unset;
    margin-top: unset;
    margin-bottom: unset;
    height: unset;
    gap: 16px;
    position: unset;
}

footer .left-column .social-icons .button-wrapper {
    display: inline-block;
    color: unset;
    pointer-events: auto;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    width: auto;
    height: auto;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
}

footer .left-column .social-icons .button-wrapper .icon-event {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
}

footer .left-column .d-flex {
    display: flex;
    justify-content: flex-start;
}

footer .left-column .company {
    display: block;
    margin-top: auto;
    font-size: 11px;
    font-weight: 600;
    color: #6f7780;
}

footer .left-column .d-flex .apple-store {
    border-radius: 5px;
    display: inline;
    border: 7px solid #000;
    margin: 5px;
}

footer .left-column .d-flex .google-play {
    border-radius: 5px;
    display: inline;
    border: 7px solid #1d1d1b;
    margin: 5px;
}

footer .right-column {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
    -webkit-box-flex: 1;
    flex-grow: 1;
    gap: 50px;
}

footer .right-column .element-column {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

footer .right-column .element-column ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

footer .right-column .element-column ul li a {
    font-size: 16px;
    color: #21242a;
}

footer .right-column .element-column ul li a:hover {
    font-size: 16px;
    color: #006dff;
}

footer .right-column .element-column ul .addition-pages a {
    color: #515767 !important;
}

footer .right-column .element-column ul .addition-pages a:hover {
    color: #515767 !important;
    font-size: 18px;
    color: #006dff;
}

@media (max-width: 768px) {
    footer {
        flex-direction: column;
    }
}

@media (max-width: 362px) {
    .st-footer footer .left-column .d-flex {
        flex-direction: column;
    }
}