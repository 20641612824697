ul,
li {
    list-style: none;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

p {
    line-height: 1.4;
}

.small-caps {
    letter-spacing: 0.05rem;
    font-size: 0.7rem;
}

.bolder {
    font-weight: bolder;
}

.lighter {
    font-weight: lighter;
}

.text-center {
    text-align: center;
}

.site-logo {
    font-size: 2rem;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.8rem;
    margin: 0 3rem 0 1rem;
}

.st-header {
    max-width: 2560px !important;
    width: 100% !important;
    margin: 0px auto !important;
    padding: 0px 48px !important;
}

@media screen and (max-width: 1024px) {
    .st-header {
        padding: 0px !important;
    }
    .main-nav li {
        margin-right: 16px !important;
    }
    .site-logo {
        font-size: 2rem;
        margin: 0 1rem 0 1rem !important;
    }

    .user-navigation {
        padding: 0px 10px 0px 0px !important;
    }
}

@media screen and (max-width: 768px) {
    .site-logo {
        font-size: 2rem;
        margin: 0 !important;
    }

    .main-nav li {
        margin-right: 0px !important;
    }
}

.user-navigation {
    display: flex;
    padding: 0px 48px 0px 0px;
    margin: auto;
}

.user-navigation button {
    display: block;
    color: #fff;
    border-radius: 20px;
    height: 40px;
    width: 100px;
    border: none;
    cursor: pointer;
}

.user-navigation button:first-child {
    background-color: #2d3037;
}

.user-navigation button:last-child {
    background-color: #006dff;
    margin-left: 10px;
}

.nav-bar {
    font-family: 'PT Sans', sans-serif;
    z-index: 100;
    display: flex;
    top: 0;
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    height: 76px;
    box-shadow: rgba(0, 0, 0, 1) 0px 1px 6px;
    background-color: #1b1f25;
}

.nav-bar .pro-badge-button {
    background-color: #252a30;
    width: 124px;
    text-wrap: nowrap;
    padding: 10px;
    border-radius: 20px;
    color: #ffffff;
    font-size: 14px;
}

.nav-bar .profile-image {
    font-family: 'PT Sans', sans-serif !important;
    margin: auto 20px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    box-shadow: unset;
    background: #855bd3;
    display: flex;
    flex-shrink: 0;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-family: Inter;
    color: #FFF;
    font-size: 20px;
}

.nav-bar .st-header {
    display: flex;
    align-items: center;
    height: 100%;
}

#drop-down-cbox {
    display: none;
}

#drop-down-cbox+label {
    position: relative;
    display: none;
    width: 72px;
    height: 72px;
    overflow: hidden;
    background-color: transparent;
    transition: all 10ms ease-in-out;
}

#drop-down-cbox+label span {
    position: absolute;
    display: block;
    background-color: #fff;
    width: 28px;
    height: 3px;
    border-radius: 2px;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 300ms ease-in-out;
}

#drop-down-cbox+label span:nth-child(1) {
    top: 35%;
}

#drop-down-cbox+label span:nth-child(2) {
    top: 50%;
}

#drop-down-cbox+label span:nth-child(3) {
    top: 65%;
}

#drop-down-cbox:checked+label span {
    background-color: white;
}

#drop-down-cbox:checked+label span:nth-child(1) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

#drop-down-cbox:checked+label span:nth-child(2) {
    left: -150%;
}

#drop-down-cbox:checked+label span:nth-child(3) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.main-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.main-nav li {
    margin-right: 20px;
    text-wrap: nowrap;
    display: block;
    height: 76px;
    line-height: 76px;
    text-align: center;
}

.main-nav li a,
.main-nav li span {
    font-size: 16px;
    color: #fff;
    display: block;
    width: 100%;
    height: 100%;
}

.main-nav li a:hover,
.main-nav li span:hover {
    color: #006dff;
}

.main-nav li>ul {
    max-height: 0px;
    transition: all 500ms ease-in-out;
    overflow: hidden;
}

.main-nav li:hover>ul {
    max-height: 180px;
    /* height x how many links */
    height: auto;
}

.main-nav li>ul li a,
.main-nav li>ul li span {
    background-color: white;
}

.main-nav li>ul li a:hover,
.main-nav li>ul li span:hover {
    background-color: #666;
    color: white;
    border-bottom: none;
}

/* Tablet and Mobile View */
@media (max-width: 768px) {
    .st-header {
        padding: 0px !important;
    }

    .site-logo {
        margin-left: -72px !important;
        font-size: 1.5rem !important;
        flex-grow: 1 !important;
        text-align: center !important;
    }

    .user-navigation button {
        display: none !important;
    }

    #drop-down-cbox+label {
        display: block !important;
    }

    .main-nav {
        max-height: 0px  !important;
        height: calc(100vh - 76px) !important;
        overflow: hidden !important;
        position: absolute !important;
        top: 74px !important;
        line-height: auto !important;
        left: 0 !important;
        width: 100% !important;
        flex-direction: column !important;
        background-color: #1b1f25 !important;
        transition: all 500ms ease-in-out !important;
    }

    #drop-down-cbox:checked~.main-nav {
        max-height: calc(100vh - 76px) !important;
        transition: all 500ms ease-in-out !important;
    }

    .main-nav li span {
        position: relative !important;
    }

    .main-nav li span:after {
        content: "" !important;
        width: 6px !important;
        height: 6px !important;
        position: absolute !important;
        top: 50% !important;
        margin-top: -2px !important;
        margin-left: 8px !important;
        transform: translateY(-50%) rotate(-45deg) !important;
        border-left: 2px solid #666 !important;
        border-bottom: 2px solid #666 !important;
    }

    .main-nav li a:hover,
    .main-nav li span:hover {
        background-color: #666 !important;
        color: white !important;
        border-bottom: none !important;
    }

    .main-nav li>ul li {
        position: relative !important;
    }

    .main-nav li>ul li a,
    .main-nav li>ul li span {
        background-color: #eee !important;
    }
}

.profile-image .hamburgerMenu {
    z-index: 100 !important;
    top: 62px !important;
    background-color: #1b1f25 !important;
}

.profile-image .hamburgerMenu .email-right {
    cursor: pointer !important;
}

.profile-image .hamburgerMenu .profile-public span {
    cursor: pointer !important;
}


/* Styles for social media icons in the main navigation bar */
.social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    padding-top: 24px;
}

.social-media a {
    color: #fff;
    text-decoration: none;
}

.social-media i {
    font-size: 26px; /* Increase size by 30% */
    color: #fff;
    vertical-align: middle; /* Center align vertically */
}

/* Styles for the app store badges and social media icons in the dropdown menu */
.hamburgerMenu .app-store-container {
    display: flex;
    justify-content: center;
  
}

.hamburgerMenu .app-store img {

   
    margin: 0 15px;
}

.hamburgerMenu .social-media-container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px; /* Add padding below the icons */
}

.hamburgerMenu .social-media {
    margin: 0 15px;
}

.hamburgerMenu .social-media i {
    font-size: 32px; /* Increase size by 2.5x */
    color: #fff;
}
